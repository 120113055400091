import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLinkedin } from "@fortawesome/free-brands-svg-icons"

import Layout from "../components/layout"
import SEO from "../components/seo"

import logo from "../images/logo.svg"

const IndexPage = () => (
  <Layout>
    <SEO title="Accueil" />
    <img
      className="logo"
      width="260"
      style={{ marginBottom: "2em" }}
      src={logo}
      alt="Hesperia Conseil Logo"
    />
    <div className="social">
      <a
        href="https://www.linkedin.com/in/christian-couten-12072a54/"
        rel="noopener noreferrer"
        target="_blank"
      >
        <FontAwesomeIcon icon={faLinkedin} />
      </a>
    </div>
    <p>Site en construction</p>
  </Layout>
)

export default IndexPage
